<template>
  <v-dialog v-model="localDialog" max-width="40%">
    <v-card>
        <form-wizard
          :form-data="formDataTermination"
          :item="item"
          :namespace="namespace"
          :title="title"
          :go-to="goTo"
          @dialog-cancel="updateDialog(false)"
          :icon="icon"
          :store="store"
          :store-action="storeActionTermination"
        ></form-wizard>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    employeeId: {
      required: false
    },
    goToUrl: {
      required: false
    },
    color:{
      required:false
    }
  },
  components: {
    FormWizard: () => import("@/components/core/form/FormWizard")
  },
  computed: {
    ...mapGetters({
      terminationReasons: "employees/terminationReasons"
    }),

    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog-update", value);
      }
    },
    storeActionTermination() {
      return "employees/terminateEmployee";
    },
    store() {
      return "employee";
    },

    icon() {
      return "";
    },
    title() {
      return 'Terminate contract';
    },
    namespace() {
      return "employee";
    },
    goTo() {
      if (this.goToUrl) {
        return this.goToUrl;
      }else return ""
    },
    formDataTermination() {
      let form = [
        {
          text: "Contract termination date*",
          value: "contract_terminated_at",
          flex: "col-sm-12 col-md-12",
          required: "required",
          fullDate: true,
          class: "",
          validate: "required"
        },
        {
          text: "Termination Reason*",
          value: "termination_type_id",
          flex: "col-sm-12 col-md-12",
          displayName: true,
          required: "required",
          treeview: true,
          selectionType: "independent",
          items: this.terminationReasons,
          class: "",
          validate: "required"
        },
        {
          text: "Termination note*",
          value: "termination_note",
          flex: "col-sm-12 col-md-12",
          required: "",
          textarea: true,
          class: "",
          validate: ""
        }
      ];
      return form;
    },
    formDataRemove() {
      let form = [];
      return form;
    }
  },
  data() {
    return {
      tab: null,
      item: {
        id: this.employeeId,
      }
    };
  },

  async mounted() {
    await this.$store.dispatch("employees/terminationReasons", { all: true });
  },
  created() {},
  methods: {
    updateDialog(dialog) {
      this.localDialog = dialog;
    },
    cancelDialog() {
      this.$emit("dialog-cancel", true);
    }
  }
};
</script>
